import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import ProjectCard from './ProjectCard';
import reisch_request from '../../images/projects/reisch_request.png';
import tuffix from '../../images/projects/tuffix.png';
import baask from '../../images/projects/baask.png';
import giveaway from '../../images/projects/giveaway.png';
import rover from '../../images/projects/titan_rover.jpg';
import personal_site from '../../images/projects/personal_site.png';
import latex from '../../images/projects/latex.png';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const ProjectCarousel = () => {
  return (
    <Swiper
      spaceBetween={5}
      slidesPerView={1}
      loop={true}
      navigation={true}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      pagination={{
        dynamicBullets: false,
        type: 'fraction',
      }}
    >
      <SwiperSlide>
        <ProjectCard
          srcImg={reisch_request}
          title="Reisch Request"
          description="An exclusive invite only movie browsing app: view top rated movies, trending movies or browse by genre. Built with the MERN stack."
        />
      </SwiperSlide>
      <SwiperSlide>
        <ProjectCard
          srcImg={tuffix}
          srcRepo="https://github.com/kevinwortman/tuffix"
          title="Tuffy's Linux"
          description="Official Linux environment for CPSC 120, 121, and 131 at California State University"
        />
      </SwiperSlide>
      <SwiperSlide>
        <ProjectCard
          srcImg={baask}
          srcRepo="https://github.com/djreisch/BAASK"
          title="Batch Auto Activator for Steam Games (BAASK)"
          description="Activate multiple game keys automatically to your Steam account."
        />
      </SwiperSlide>
      <SwiperSlide>
        <ProjectCard
          srcImg={giveaway}
          srcRepo="https://github.com/djreisch/reddit-giveaway-bot"
          title="Reddit Giveaway Bot"
          description="A Reddit bot that creates and manages custom giveaways for digital items, such as product keys, game codes, or coupons"
        />
      </SwiperSlide>
      <SwiperSlide>
        <ProjectCard
          srcImg={rover}
          srcRepo="https://github.com/djreisch/reddit-giveaway-bot"
          title="Titan Rover"
          deploy="https://titanrover.com/"
          description="A student-led organization founded in 2013 to compete in the Mars Society’s University Rover Challenge (URC)"
        />
      </SwiperSlide>
      <SwiperSlide>
        <ProjectCard
          srcImg={personal_site}
          title="Portfolio Website"
          description="My portfolio website. Built with React and Chakra UI. CI/CD setup using GitHub and AWS Amplify"
        />
      </SwiperSlide>
      <SwiperSlide>
        <ProjectCard
          srcImg={latex}
          srcRepo="https://github.com/djreisch/Awesome-CV"
          title="CI/CD LaTeX Resume"
          deploy="https://resume.reisch.dev"
          description="A CI/CD Resume using GitHub, AWS CodeBuild, CloudFront, CloudWatch, and S3"
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default ProjectCarousel;
