import React from 'react';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import NavBar from '../components/navigation/NavBar';
import Education from '../components/education/Education';
import Tools from '../components/tools/Tools';
import Experience from '../components/experience/Experience';
import Projects from '../components/projects/Projects';
import Banner from '../components/Banner';
import Contact from '../components/contact/Contact';
import Skills from '../components/skills/Skills';

import '@fontsource/open-sans';
import '@fontsource/roboto';

import theme from '../styles/theme';
import Footer from '../components/Footer';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        direction="column"
        justify="center"
        color="white"
        align="center"
        maxW="100vw"
        overflowX={'clip'}
        fontSize={['sm', 'sm', 'sm']}
        bgGradient="linear-gradient(to bottom, #000000,#000000,#030108, #1d0422, #220726, #431a54, #000000,)"
      >
        <Banner />
        <NavBar />
        <Flex
          direction="column"
          px={{ base: 4, sm: 4, md: 8, lg: 16, xl: 64 }}
          fontSize={{ base: 'sm', sm: 'sm', md: 'md', lg: 'lg' }}
          w="100%"
          align="center"
        >
          <Skills />
          <Tools />
          <Experience />
          <Education />
          <Projects />
          <Contact />
        </Flex>
        <Footer />
      </Flex>
    </ChakraProvider>
  );
}

export default App;
