import React from 'react';
import { Link, Text, Flex } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const ProjectCard = ({ srcImg, srcRepo, description, title, deploy }) => {
  return (
    <Flex
      w="100%"
      h="80vh"
      p="8"
      direction="column"
      justify={'flex-end'}
      bgImage={`linear-gradient(to bottom, rgba(0, 0, 0, 0),  rgba(0, 0, 0, 1)),url(${srcImg})`}
      bgSize={'cover'}
      mr="8"
      borderRadius={'lg'}
    >
      <Text fontWeight={'extrabold'} fontSize={'3xl'}>
        {title}
      </Text>
      {srcRepo ? (
        <Link href={srcRepo} isExternal>
          Github Repository <ExternalLinkIcon mx="2px" />
        </Link>
      ) : (
        <></>
      )}
      {deploy ? (
        <Link href={deploy} isExternal>
          Project Deployment <ExternalLinkIcon mx="2px" />
        </Link>
      ) : (
        <></>
      )}
      <Text>{description}</Text>
    </Flex>
  );
};

export default ProjectCard;
