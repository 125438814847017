import React from 'react';

import { Flex, Heading, Box } from '@chakra-ui/react';
import ProjectCarousel from './ProjectCarousel';

const Projects = () => {
  return (
    <Flex
      w="100%"
      direction="column"
      color="white"
      py="16"
      id="projects"
    >
      <Heading as="h1" mb="4" textAlign={'center'}>
        Projects
      </Heading>
      <Box>
        <ProjectCarousel />
      </Box>
    </Flex>
  );
};

export default Projects;
