const classList = [
  {
    title: `CPSC 120 - Introduction to Programming `,
    description: `Introduction to the concepts underlying all computer programming: design and execution of programs; sequential nature of programs; use of assignment, control and input/output statements to accomplish desired tasks; design and use of functions. Structured and object-oriented methodologies. (1.5 hours lecture, 3 hours laboratory)`,
    language: [`C++`],
  },
  {
    title: `CPSC 121 - Object-Oriented Programming`,
    description: `The object-oriented programming paradigm: classes, member functions, interfaces, inheritance, polymorphism, and exceptions. Design practices including encapsulation, decoupling, and documentation. Pointers/references and memory management. Recursion. (2 hours lecture, 2 hours activity)`,
    language: [`C++`],
  },
  {
    title: `CPSC 131 - Data Structures`,
    description: `Classical data structures: vector, linked list, stack, queue, binary search tree, and graph representations. Worst-case analysis, amortized analysis, and big-O notation. Object-oriented and recursive implementation of data structures. Self-resizing vectors and self-balancing trees. Empirical performance measurement.`,
    language: [`C++`],
  },
  {
    title: `CPSC 223J - Java Programming`,
    description: `Characteristics of Java: portable, robust, secure, object-oriented, high performance; using the Java environment; server administration; types, expressions and control flow; classes, interfaces and packages; threads; exceptions; class libraries; Java for the Internet; tools, the Java Virtual machine. (2 hours lecture, 2 hours lab per week)`,
    language: [`Java`],
  },
  {
    title: `CPSC 240 - Computer Organization and Assembly Language`,
    description: `Digital logic and architecture of a computer system, machine level representation of data, memory system organization, structure of low-level computer languages. Machine, assembly, and macro language programming. Principles of assembler operation, input-output programming, interrupt/exception handling. Laboratory programming assignments. (2 hours lecture, 2 hours laboratory)`,
    language: [`ASM`],
  },
  /*{
    title: `CPSC 311 - Tech Writing for Comp Sci`,
    description: `Develop documentation skills as used in the computer field. Proposals, feasibility studies, user guides and manuals, business communication and technical presentation. Case studies in professional ethics. Written and oral reports required.`,
    language: [],
  },*/
  {
    title: `CPSC 315 - Prof Ethics in Computing`,
    description: `Ethics and moral philosophy as applied to software and digital artifacts. Notions of rights, responsibilities, property, ownership, privacy, security, and professional ethics. Security obligations. Intellectual propertystatutes, licenses, and their terms. Oral and written reports are required.`,
    language: [],
  },
  {
    title: 'CPSC 323 - Compilers and Languages',
    description:
      'Basic concepts of programming languages and principles of translation. Topics include history of programming languages, various programming paradigms, language design issues and criteria, design of compilers for modern programming languages.',
    language: ['C++'],
  },
  {
    title: `CPSC 332 - File Structures and Database Systems`,
    description: `Fundamental theories and design of database systems, the Structured Query Language (SQL), basic concepts and techniques of data organization in secondary storage. Topics include introduction to database systems, ER model, relational model, index structures and hashing techniques.`,
    language: [`SQL`, `PHP`, `HTML`, `CSS`],
  },
  {
    title: 'CPSC 335 - Algorithm Engineering',
    description:
      'Algorithm design using classical patterns: exhaustive search, divide and conquer, randomization, hashing, reduction, dynamic programming, and the greedy method. Asymptotic and experimental efficiency analysis. NP-completeness and decidability. Implementing algorithms to solve practical problems.',
    language: ['C++', 'Python'],
  },
  /*{
    title: 'CPSC 349 - Web Front-End Engineering',
    description:
      'Concepts and architecture of interactive web applications, including markup, stylesheets and behavior. Functional and object-oriented aspects of JavaScript. Model-view design patterns, templates and frameworks. Client-side technologies for asynchronous events, real-time interaction and access to back-end web services.',
    language: [
      'Javascript',
      'HTML',
      'CSS',
      'React',
      'Bootstrap',
      'Gatsby',
      'Firebase',
    ],
  },*/
  {
    title: 'CPSC 351 - Operating Systems Concepts',
    description:
      'Resource management, memory organization, input/output, control process synchronization and other concepts as related to the objectives of multi-user operating systems.',
    language: ['C++', 'C'],
  },
  /*{
    title: `CPSC 353 - Introduction to Computer Security `,
    description: `Security goals, security systems, access controls, networks and security, integrity, cryptography fundamentals, authentication. Attacks: software, network, website; management considerations, security standards in government and industry; security issues in requirements, architecture, design, implementation, testing, operation, maintenance, acquisition, and services.`,
    language: [],
  },*/
  {
    title: 'CPSC 362 - Foundations of Software Engineering',
    description:
      'Basic concepts, principles, methods, techniques and practices of software engineering. All aspects of the software engineering fields. Use Computer-Aided Software Engineering (CASE) tools.',
    language: ['Python'],
  },
  /*{
    title: 'CPSC 375 - Introduction to Data Science and Big Data',
    description:
      'Techniques for data preparation, exploratory analysis, statistical modeling, machine learning and visualization. Methods for analyzing different types of data, such as natural language and time-series, from emerging applications, including Internet-of-Things. Big data platforms. Projects with real-world data.',
    language: ['R', 'R Studio'],
  },*/
  /*{
    title: 'CPSC 440 - Computer System Architecture',
    description:
      'Computer performance, price/performance, instruction set design and examples. Processor design, pipelining, memory hierarchy design and input/output subsystems.',
    language: ['MIPS Assembly'],
  },*/
  /*{
    title: 'CPSC 449 - Web Back-End Engineering',
    description:
      'Design and architecture of large-scale web applications. Techniques for scalability, session management and load balancing. Dependency injection, application tiers, message queues, web services and REST architecture. Caching and eventual consistency. Data models, partitioning and replication in relational and non-relational databases.',
    language: ['Python'],
  },*/
  {
    title: 'CPSC-456 - Network Security Fundamentals',
    description:
      'Learn about vulnerabilities of network protocols, attacks targeting confidentiality, integrity and availability of data transmitted across networks, and methods for diagnosing and closing security gaps through hands-on exercises.',
    language: ['Python', 'GNS3'],
  },
  {
    title: 'CPSC 458 - Malware Analysis',
    description:
      'Introduction to principles and practices of malware analysis. Topics include static and dynamic code analysis, data decoding, analysis tools, debugging, shellcode analysis, reverse engineering of stealthy malware and written presentation of analysis results.',
    language: ['Shell', 'Fiddler'],
  },
  {
    title: 'CPSC 471 - Computer Communications',
    description:
      'Introduction to digital data communications. Terminology, networks and their components, common-carrier services, telecommunication facilities, terminals, error control, multiplexing and concentration techniques.',
    language: ['Python', 'Wireshark'],
  },
  /*{
    title: 'CPSC 481 - Artificial Intelligence',
    description:
      'Using computers to simulate human intelligence. Production systems, pattern recognition, problem solving, searching game trees, knowledge representation and logical reasoning. Programming in AI environments.',
    language: ['Python'],
  },*/
  /*{
    title: 'CPSC 485 - Computational Bioinformatics',
    description:
      'Algorithmic approaches to biological problems. Specific topics include motif finding, genome rearrangement, DNA sequence comparison, sequence alignment, DNA sequencing, repeat finding and gene expression analysis.',
    language: ['Python'],
  },*/
  {
    title: 'CPSC 490 - Undergraduate Seminar in Computer Science',
    description:
      'Review of foundational computer science theories and principles, real-world application development methods and processes, and industry practices. Survey of modern computing technologies. Research methods. Identification of research or practical application problems. Writing and presenting a proposal for a capstone project.',
    language: ['C++', 'Python'],
  }
];

export default classList;
