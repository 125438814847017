import { Link } from 'react-scroll';
const NavLink = ({ linkName, linkDst }) => {
  return (
    <Link
      spy={true}
      smooth={true}
      duration={500}
      offset={-50}
      to={linkDst}
      activeClass="active"
      className="navLink"
    >
      {linkName}
    </Link>
  );
};

export default NavLink;
