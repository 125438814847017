import React from 'react';
import { Flex, Heading, Spacer } from '@chakra-ui/react';
import { FiMapPin } from 'react-icons/fi';
import {
  AiOutlineLinkedin,
  AiOutlineGithub,
  AiOutlineMail,
} from 'react-icons/ai';
import logo from '../../images/logo.png';
import Point from './Point';

const Contact = () => {
  return (
    <Flex
      boxSize={{ base: 'md', sm: 'md', lg: 'xl' }}
      direction="column"
      align="center"
      justify="center"
      p="4"
      bgImage={logo}
      bgSize={'100%'}
      borderRadius={'lg'}
      mb="32"
    >
      <Heading mb="8" align="center" id="contact">
        Contact Me
      </Heading>
      <Spacer />
      <Flex wrap="wrap" align="center" justify={'center'}>
        <Point icon={FiMapPin} text={'Orange County, CA'} />
        <Point
          icon={AiOutlineGithub}
          text={'GitHub'}
          link={'https://github.com/djreisch'}
        />
        <Point
          icon={AiOutlineLinkedin}
          text={'LinkedIn'}
          link={'https://www.linkedin.com/in/danielreisch/'}
        />
        <Point
          icon={AiOutlineMail}
          text={'Email'}
          link={'mailto:daniel@reisch.dev'}
        />
      </Flex>
    </Flex>
  );
};

export default Contact;
