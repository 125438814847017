// import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Heading, Flex, Text } from '@chakra-ui/react';
import background_image from '../images/han-tu-M8b7NRHPwhI-unsplash.jpg';
import { HeadingVariant, SubheadingVariant } from '../variants/variants';

const MotionHeader = motion(Heading);
const MotionSubheader = motion(Text);

const Banner = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      h="80vh"
      w="100%"
      color="white"
      bgImage={`linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),url(${background_image})`}
      bgPosition="bottom"
      backgroundSize="cover"
    >
      <MotionHeader
        variants={HeadingVariant}
        initial="hidden"
        animate="visible"
        size="2xl"
        align="center"
        userSelect="none"
      >
        Daniel Reisch
      </MotionHeader>
      <MotionSubheader
        variants={SubheadingVariant}
        initial="hidden"
        animate="visible"
        align="center"
        fontWeight="lighter"
        fontSize="2xl"
        userSelect="none"
        mt="4"
      >
        SysAdmin · DevSecOps · Cloud Engineer
      </MotionSubheader>
    </Flex>
  );
};

export default Banner;
