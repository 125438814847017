import { Flex, Text, Icon, Link } from '@chakra-ui/react';

const Point = ({ icon, text, link }) => {
  return (
    <Flex align="center" px="2">
      <Icon as={icon} mr="2" />
      {link ? (
        <Link href={link} isExternal>
          {text}
        </Link>
      ) : (
        <Text>{text}</Text>
      )}
    </Flex>
  );
};

export default Point;
