import { Image, Flex, Text } from '@chakra-ui/react';

const LanguageTile = ({ imgSrc, name }) => {
  return (
    <Flex
      boxSize={{ base: '100px', sm: '100px', lg: '150px' }}
      p={{ base: '4', sm: '4', lg: '8' }}
      m={'1'}
      direction="column"
      align="center"
      justify="center"
      border="2px solid #431a54"
      borderRadius="md"
    >
      <Image
        src={imgSrc}
        boxSize={{ base: '50px', sm: '50px', lg: '90px' }}
        objectFit="scale-down"
      />
      <Text mt={{ base: '2px', sm: '2px', lg: '12px' }} color="#fff">
        {name}
      </Text>
    </Flex>
  );
};

export default LanguageTile;
