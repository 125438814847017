import { Flex, Text, Link, Heading } from '@chakra-ui/react';
const Footer = () => {
  return (
    <Flex
      direction="column"
      w="100%"
      color="white"
      p="4"
      align="center"
      fontSize="sm"
    >
      <Flex mb="8">
        <Flex direction="column" mx="4">
          <Heading>Contact</Heading>
          <Link href="https://github.com/djreisch" isExternal>
            GitHub
          </Link>
          <Link href="https://www.linkedin.com/in/danielreisch" isExternal>
            LinkedIn
          </Link>
          <Link href="mailto:daniel@reisch.dev" isExternal>
            Email
          </Link>
          <Link href="https://resume.reisch.dev" isExternal>
            Resume
          </Link>
        </Flex>
        <Flex direction="column" mx="4">
          <Heading>Stack</Heading>
          <Link href="https://reactjs.org/" isExternal>
            React.js
          </Link>
          <Link href="https://chakra-ui.com/" isExternal>
            Chakra UI
          </Link>
          <Link href="https://www.framer.com/motion/" isExternal>
            Framer Motion
          </Link>
          <Link href="https://swiperjs.com/" isExternal>
            Swiper
          </Link>
        </Flex>
      </Flex>
      <Flex direction="row" align="center" justify="space-between">
        <Text ml="4">
          Website designed, created, and deployed by Daniel Reisch &copy; 2021
        </Text>
      </Flex>
    </Flex>
  );
};

export default Footer;
