import {
  Flex,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Badge,
} from '@chakra-ui/react';
import classes from '../../classllist';

const Coursework = () => {
  return (
    <Flex
      w={{ sm: '100%', lg: '50%' }}
      direction="column"
      justify="center"
      my="4"
      p="4"
      bgGradient="linear(to-l, #ad5389, #3c1053)"
      color="white"
      borderRadius={'lg'}
      boxShadow="dark-lg"
    >
      <Heading as="h1" textAlign={'center'} id="classes">
        Computer Science Coursework
      </Heading>
      <Accordion allowMultiple>
        {classes.map(x => {
          return (
            <AccordionItem key={x.title}>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading as="h3" size="sm">
                    {x.title}
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel w="100%" pb={3}>
                <Text>{x.description}</Text>
                <br></br>
                <Text>
                  {x.language.map(i => {
                    return (
                      <Badge
                        m="1"
                        colorScheme="whiteAlpha"
                        color="white"
                        key={i}
                      >
                        {i}
                      </Badge>
                    );
                  })}
                </Text>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Flex>
  );
};

export default Coursework;
