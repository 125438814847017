import { Flex, Heading, Text, Icon } from '@chakra-ui/react';

const SkillBlock = ({ children, skillTitle, skillDescription, icon }) => {
  return (
    <Flex
      align={'center'}
      border="2px solid #431a54"
      borderRadius={'md'}
      mb="8"
      p="4"
    >
      <Icon as={icon} mr="12" boxSize="50px" />
      <Flex direction="column">
        <Heading fontSize={'lg'} mb="2">
          {skillTitle}
        </Heading>
        <Text>{skillDescription}</Text>
      </Flex>
    </Flex>
  );
};

export default SkillBlock;
