export const ImageVariant = {
  hidden: { opacity: 0, y: '-100vh' },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1,
      duration: 1,
      ease: 'easeOut',
      type: 'spring',
      stiffness: 100,
    },
  },
};

export const HeadingVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 0.1, duration: 2 } },
};

export const SubheadingVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 1, duration: 2 } },
};
