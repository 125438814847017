import { Flex, Heading, Text } from '@chakra-ui/react';
import { AiFillCode } from 'react-icons/ai';
//import { ImStack, ImDatabase } from 'react-icons/im';
import { AiOutlineSecurityScan } from 'react-icons/ai';
import { WiCloudyWindy } from 'react-icons/wi';
import SkillBlock from './SkillBlock';


const Skills = () => {
  return (
    <Flex
      direction={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
      py={{ base: '4', sm: '4', lg: '16' }}
      w="100%"
      mt="12"
      color="white"
      align="center"
      id="skills"
    >
      <Flex
        maxW={{ base: '100%', sm: '100%', lg: '40%' }}
        mr={{ base: '0', sm: '0', lg: '12' }}
        textAlign={{ base: 'center', sm: 'center', lg: 'start' }}
        direction="column"
      >
        <Heading mb="2">System Administration and DevOps Skillset</Heading>
        <Text mb={{ base: '4', sm: '4', lg: '0' }} fontStyle={'italic'}>
          Passionate in the design and creation of scalable systems and automation.
          Diverse knowledge ranging across all points of administration and development.
        </Text>
      </Flex>
      <Flex maxW={{ base: '100%', sm: '100%', lg: '50%' }} direction="column">
        <SkillBlock
          skillTitle={'System Administration'}
          skillDescription={
            'A seasoned System Administrator with numerous specialized areas including time spent as an SRE to makes sure production stays up even during stormy days.'
          }
          icon={AiFillCode}
        />
        <SkillBlock
          skillTitle={'Cloud Engineering'}
          skillDescription={
            'Experienced with both private and public clouds, hosted operations, pipelines, automation, and hyperconverged infrastructures.'
          }
          icon={WiCloudyWindy}
        />
        <SkillBlock
          skillTitle={'DevSecOps'}
          skillDescription={
            'Server side expertise with a refined knowledge in Software Engineering aides in the ability to diagnose development and security related operations.'
          }
          icon={AiOutlineSecurityScan}
        />
      </Flex>
    </Flex>
  );
};

export default Skills;
